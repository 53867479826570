import { getAllRailsThunk } from '@store/rails-slice/_thunks'
import { getChannelThunk } from '@store/channel-slice'
import { useAppDispatch, useAppSelector as useSel } from '@store/index'
import { selectUserId } from '@store/user-slice/_selectors'
import { useEffect } from 'react'
import {
  selectChannelPassesByPathname,
  selectChannelPropByChannelPathname,
  selectChannelSubscriptionIdsByChannelPathname,
} from '@store/channel-slice/_selectors'
import { getFeaturedContentByChannelIdThunk } from '@store/contents-slice/_thunks'
import { isEmptyArray } from '@root/utils/arrays'
import { getChannelPassStatusThunk } from '@store/pass-slice/_thunks'
import UseHasWhiteLabel from 'hooks/white-label/use-has-white-label'
import { getSubscriptionStatusThunk } from '@store/subscriber-slice/_thunks'

export function useApiData(channelPathname: string): void {
  const dispatch = useAppDispatch()
  const userId = useSel(selectUserId)
  const channelId = useSel(selectChannelPropByChannelPathname(channelPathname)('channelId'))
  // Check channel passes
  const passes = useSel((state) => selectChannelPassesByPathname(state, channelPathname))

  // Check passes
  const subscriptionIds = useSel((state) =>
    selectChannelSubscriptionIdsByChannelPathname(state, channelPathname),
  )

  // Get the white label data
  UseHasWhiteLabel({
    fetchChannel: false,
    fetchOrganisation: true,
  })

  useEffect(() => {
    ;(async () => {
      dispatch(getChannelThunk(channelPathname))
    })()
  }, [channelPathname])

  useEffect(() => {
    ;(async () => {
      dispatch(getAllRailsThunk({ channelPathname }))
    })()
  }, [userId, channelId])

  useEffect(() => {
    if (channelId) {
      dispatch(getFeaturedContentByChannelIdThunk(channelId))
    }
  }, [userId, channelId])

  // Fetch Rails data
  useEffect(() => {
    if (userId && !isEmptyArray(passes) && channelId) {
      const passIds = passes.map((pass) => pass.passId)
      dispatch(getChannelPassStatusThunk({ channelId, passIds, channelPathname }))
    }
  }, [userId, passes, channelId, dispatch])

  // Check if user is subscribed by subscription id
  useEffect(() => {
    subscriptionIds.forEach((subscriptionId) => {
      if (subscriptionId && userId) {
        dispatch(getSubscriptionStatusThunk({ channelPathname, subscriptionId: subscriptionId }))
      }
    })
  }, [subscriptionIds, channelPathname, userId])
}
